import { ASSET_REDIRECT_TARGET } from "projects/sso/src/app/data";

export const environment = {
    e2e: false,
    production: true,
    firebase: {
        apiKey: "AIzaSyCex5F5rXIK_p9neE_M-9t3s8_6KKnrxsg",
        authDomain: "cwl-63f89c71fc06-qa.firebaseapp.com"
    },
    brain_base_url: "https://luna.api-test.deloitte.camp",
    authParams: ["oidc.azure", "google"],
    signInType: 'signInWithPopup',
    breadcrumbs: {
        assetOwner: {
            aoh: "Asset Owner Hub",
            assetManagement: "Asset Management",
            principleAssetQuestions: "Principle asset questions",
            offerRequestDetail: "Offer request detail",
            assetPreview: "Preview",
        },
        store: {
            deloitteAiCatalog: "Deloitte.Ai",
            checkout: "Checkout",
            standingOrderDetail: "Standing order detail",
            storefront: "Storefront",
            orderHistory: "Order History",
            myEntitlements: "My Entitlements"
        }
    },
    aoh_url: "https://aoh-qa.luna.cwl.camp",
    store_url: "https://store-qa.luna.cwl.camp",
    api_base_url: "https://luna.api-test.deloitte.camp",
    hasEnterpriseAssetSearch: false,
    asset_redirect_uri: {},
    onboarding: {
        displayVideo: true,
        video_url: "https://storage.googleapis.com/dcsp-catalog-assets-/data/products/Deloitte%20AI%20Video/Deloitte%20AI%20-%20Asset%20Owner%20Hub.mp4",
        development: {
            frontend_url: "https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Frontend_Development_Kit/Deloitte.AI%20Frontend%20Development%20Kit.pdf",
            backend_url: "https://bitbucket.de.deloitte.com/projects/AI/repos/dcsp-python-framework/browse",
            business_support_email: "mailto:GlobalDeloitteAi@deloitte.com?subject=Question%20regarding%20Deloitte%20AI%20Marketplace",
        }
    },
    barrierQuestionsAbort: {
        mailAddress: "mahorst@deloitte.de"
    },
    support: {
        ccEmail: "",
        contact: "mailto:GlobalDeloitteAi@deloitte.com?subject=Question%20regarding%20Deloitte%20AI%20Marketplace"
    },
    onboarding_options: {
        "gen_ai": {
            api_url: "https://luna-geniusai.connect-test.api.deloitte.ai",
            app_url: "https://geniusai-test.luna.cwl.camp/home"
        },
        "manual": {},
        "api_passthrough": {},
        "soft_onboarding": {}
    }
};
